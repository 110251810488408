<template>
  <div class="row" v-if="bills.length > 0">
    <div class="col-md-12">
      <h2>Archived Bills</h2>
      <p>
        The bills below have been taken from our archive on a previous system.
        It will display all bills that are associated to you, and may include
        other apartments in your name other than the above.
      </p>
      <p>
        If you suspect any bills are missing or need clarification, please raise
        a ticket in our
        <a href="https://dataenergy.co.uk/residents" target="_blank"
          >Residents Hub</a
        >
      </p>

      <div
        class="block tabular payment"
        v-bind:key="item.id"
        v-for="item in bills"
      >
        <div class="row">
          <div class="col-1">
            <span class="icon" style="position:absolute; left: 0px;">
              <font-awesome-icon icon="file-alt" />
            </span>
          </div>
          <div class="col-6">
            <div class="head">{{ item.starts }} - {{ item.ends }}</div>
            <div class="sub">
              {{ item.invoice_number }}
            </div>
          </div>
          <div class="col-3">
            <div class="price">£{{ item.bill_total }}</div>
          </div>
          <div class="col-2">
            <span
              @click="downloadBill(item.id, item.end_file)"
              class="icon is-download"
              style="position:absolute; right: 0px; font-size: 1.5rem;"
            >
              <font-awesome-icon icon="download" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";

import axios from "axios";

library.add(fas);

export default {
  name: "HistoricBills",
  props: ["reference", "bills", "sage"],
  components: {
    FontAwesomeIcon
  },
  methods: {
    downloadBill(billReference, date) {
      axios({
        url:
          process.env.VUE_APP_API_URL +
          "dashboard/billing/" +
          this.reference +
          "/historic-bill/" +
          billReference,
        method: "GET",
        responseType: "blob"
      }).then(response => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", "historic_bill_" + date + ".pdf");
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../scss/dashboard.scss";

p {
  text-align: left;
  padding-left: 10px;
}
</style>
